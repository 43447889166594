/*******************************
         Site Overrides
*******************************/
.ui.container{
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.ui.container.PointsTransaction__points__container{
    justify-content: center;
    align-items: flex-end;
    max-width: 40% !important;
    max-height: 4.5rem;
    overflow-y: hidden
}

.ui.container.ExercisesTransaction__points__container{
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.ui.container.HomePage,
 .ui.container.CassetteInfoPage{
    background-color: #FFF4F4;
    position: relative;
    padding: 1.71rem 0;
    min-height: 100vh;
    height: 100vh;
    background-image: url("/images/background.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

@media(max-width: 540px) {
    .ui.container.HomePage,
      .ui.container.CassetteInfoPage {
        background-image: url("/images/background.png");
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }
}

.ui.container.PointsTransactions,
.ui.container.ExercisesTransactions {
    padding-top: 3.525rem;
    background-color: #FEF4F4;
    min-height: 100vh;
    height: 100vh;
    padding-bottom: 6rem;
    background-image: url("/images/background.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.ui.container.LoginPage {
    margin: 0 48px;
}

.ui.container.ResetPassword,
.ui.container.SetPassword,
.ui.container.SetPasswordSuccessfully,
.ui.container.LoginPage {
    height: 100vh;
    position: relative;
    background-image: url("/images/background.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    align-items: center;
    justify-content: center;
    background-color: #FFF4F4;
}

@media(max-width: 540px) {
    .ui.container.ResetPassword,
    .ui.container.SetPassword,
    .ui.container.SetPasswordSuccessfully,
    .ui.container.LoginPage {
        background-image: url("/images/background.png");
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }
}

.ui.container.ResetPassword > .ui.container.ResetPassword__formContainer,
.ui.container.SetPassword > .ui.container.SetPassword__formContainer,
.ui.container.LoginPage > .ui.container.LoginPage__formContainer {
   position: absolute;
   bottom: 8%;
   left: 50%;
   transform: translateX(-50%);
   align-items: center; 
   width: calc(100% - 56px) !important;
}

.ui.container.ProfilePage {
    min-height: 100vh;
    height: 100vh;
    width: 100%;
    background-image: url("/images/background.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.ui.container.ProfileContainer{
    border-bottom: 1px solid rgba(0,0,0,0.1);
    padding-top: 1rem;
    padding-bottom: 5px;
}

.ui.container.NotFoundPage{
    height: 100vh;
    background-color: #ECBFBB;
    align-items: center;
    justify-content: center;
}

.ui.container::-webkit-scrollbar {
  display: none;
}

@media(max-width: 767px){
    .ui.container.NotFoundPage{
        justify-content: flex-start;
    }
}
@media(min-width: 768px){
    .ui.container.HomePage{
        padding: 1.71rem;
    }
}
