/*******************************
         Site Overrides
*******************************/
.ui.inverted.red.segment{
    background-color:  #e95b55 !important;
}

.ui.segment.PointsTransaction,
.ui.segment.ExercisesTransaction{
    position: relative;
    display: flex;
    flex-direction: row;
    border-radius: 0;
    border: none;
    padding: 14px 28px;
}

.ui.segment.PointsSummary {
    border-radius: 3rem;
    padding-top: 24px;
    padding-bottom: 30px;
    margin-left: 10px;
    margin-right: 10px;
}

.ui.segment.ResetPasswordLink {
    margin-top: 3rem;
    border-radius: 3rem;
    position: relative;
    margin-right: 10px;
    height: 40px;
    display: flex;
    align-items: center;
    flex-direction: row;
}
.ui.segment.PointsCassette {
  border-radius: 40px;
  box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.2);
}

.ui.segment.PointsHistoryLink {
    margin-top: -10px;
    border-radius: 16px;
    position: relative;
    margin-left: 10px;
    margin-right: 10px;
    height: 80px;
    display: flex;
    align-items: center;
    flex-direction: row;
}

.ui.segment.FinanceLink {
    margin-top: -10px;
    border-radius: 3rem;
    position: relative;
    margin-left: 10px;
    margin-right: 10px;
    height: 40px;
    display: flex;
    align-items: center;
    background: #1C98BE;
    flex-direction: row;
}

.ui.segment.Footer {
    margin-top: 50px;
    border-top-left-radius: 2rem;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    padding: 24px 24px 40px 24px;
    padding-bottom: 5rem;
    font-size: 0.8571rem;
    line-height: 1.429rem;
    font-family: NotoSansRegular;
}
@media(min-width: 768px){
    .ui.segment.PointsHistoryLink{
        margin-left: 0;
        margin-right: 0;
    }
	.ui.segment.FinanceLink{
        margin-left: 0;
        margin-right: 0;
    }
}
