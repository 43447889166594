/*******************************
         Site Overrides
*******************************/
.ui.form .FormInput input,
.ui.form input.FormInput {
    max-width: 420px;
    width: 100%;
    border-left: 0px;
    border-right: 0px;
    border-top: 0px;
    border-color: black;
    border-radius: 0;
}

.ui.form .field .prompt.label {
    max-width: 420px;
    position: absolute;
    bottom: -50px;
    line-height: 1.25;
}

.ui.form .error {
    position: relative;
    margin-bottom: 70px !important;
}

.ui.label {
    display: block !important;
}

.ui.form .success.message {
    display: block;
}