/*******************************
         Site Overrides
*******************************/
.ui.header{
    letter-spacing: -0.48px;
    text-align: center;
    margin-top: 0px;
}
h1.ui.header{
    line-height: 5.92rem;
}
h3.ui.header, h4.ui.header{
    line-height: 3rem;
    letter-spacing: -0.32px;
}
h4.ui.header{
    font-family: NotoSansCJKMedium;
}
h5.ui.header{
    font-family: NotoSansCJKLight;
    line-height: 1.71rem;
    margin-top: 0px;
}

h5.ui.purple.header.PointsTransactions__subheader{
    margin-bottom: 0px;
    margin-top: 0.625rem;
}

.ui.header.HomePage__header {
    margin: 15px 0 10px;
    font-size: 1.714rem;
    line-height: 2.571rem;
}

.ui.header.HomePage__header > span{
  font-size: 1rem;
  letter-spacing: -0.24px;
  vertical-align: top;
  line-height: 1rem;
  font-family: 'NotoSansBold';
  font-style: normal;
  font-weight: bold;
}

.ui.header.CassetteInfo__header {
    margin: 15px 0 10px;
    font-size: 1.714rem;
    line-height: 2.571rem;
    font-family: Hiragino Sans;
}

.ui.header.CassetteInfo__header > span{
  font-size: 1.3rem;
  vertical-align: top;
  line-height: 1rem;
  font-family: 'Hiragino Sans';
  font-style: normal;
  font-weight: bold;
}

.ui.header.HomePage__current-date {
    font-size: 1rem;
    letter-spacing: -0.12px;
}

.ui.header.PointsSummary__label {
    line-height: 1.714rem;
}

.ui.header > .PointsSummary__subLabel {
    font-family: NotoSansCJKLight;
    font-size: 0.8571rem;
    line-height: 0.8571rem;
}

.ui.header.PointsSummary__cassetteHeader {
    font-family: NotoSansCJKRegular;
    width: 180px;
    margin: 0 auto;
    marginBottom: 0;
    text-align: justify;
    fontSize: 18px;
    position: relative;
}

.ui.header.PointsSummary__cassetteHeader > .PointsSummary__cassetteName {
    letter-spacing: -1px;
}

.ui.header.PointsSummary__cassetteHeader > .PointsSummary__cassettePoint {
    float: right;
}

.PointsCassette__cassetteTexts {
  font-family: NotoSansCJKRegular;
	text-align: center;
	color: #ECBFBB !important;
}

.PointsCassette__cassetteTexts > span {
  font-family: NotoSansRegular;
}

.ui.header .PointsCassette__cassettePoint {
  font-family: EinaBold;
}

h1.ui.header.PointsCassette__numPoints {
  line-height: 3rem;
}

.ui.header .PointsCassette__unitType {
  font-size: 1.71rem;
  font-family: EinaBold;
}

.ui.header.PointsTransactions__current-points {
    margin-top: 0.375rem;
    margin-bottom: 1.875rem;
}

.ui.header.PointsTransactions__current-date,
.ui.header.ExercisesTransactions__current-date
 {
    margin-top: 0.375rem;
    margin-bottom: 1rem;
}

.ui.header.PointsTransactions__header,
.ui.header.ExercisesTransactions__header{
    text-align: left;
    margin-bottom: 0px;
    margin-top: 0.625rem;
    font-family: Hiragino Sans;
}
.ui.header.PointsTransaction__wrapper {
    display: flex;
    align-items: center;
}

.ui.header.PointsTransaction__source {
    margin-bottom: 0.5rem;
    font-family: NotoSansCJKLight;
    font-size: 1.077rem;
}

.ui.header.PointsTransaction__points {
    font-size: 2.2rem;
    font-family: EinaSemiBold;
    line-height: 2.2rem;
    margin-bottom: 0rem;
}
.ui.header.PointsTransaction__points > span {
    font-size: 1.231rem;
    margin-left: -0.3rem;
}

.ui.header.ExerciseTransaction__stock {
    font-size: 2.2rem;
    font-family: EinaSemiBold;
    line-height: 2.2rem;
    margin-bottom: 0rem;
    text-align: right;
}
.ui.header.ExerciseTransaction__stock > span {
    font-size: 1.231rem;
    margin-left: -0.3rem;
}

.ui.header.PointsHistoryLink__label {
  font-family: NotoSansCJKRegular;
    margin: 0;
    text-align: left;
}

.ui.header.ResetPasswordLink__label{
    margin: 0;
    font-family: NotoSansCJKRegular;
    font-size: 1.0rem;
}

.ui.header.FinanceLink__label {
    margin: 0;
    color: #FFF082 !important;
}

.ui.header.NotFoundPage__header{
    font-size: 8.5rem;
    font-family: EinaBold;
    line-height: inherit;
    letter-spacing: -20px;
}
.ui.header.NotFoundPage__header > .image:not(.icon), .ui.header.NotFoundPage__header > img{
    width: 0.65em;
    margin-top: 0px;
    margin-bottom: 1.5rem;
    margin-left: 1.5rem;
}

.ui.header.ProfileContainer__label {
    margin-bottom: 0px;
    font-size: 1.15rem;
    line-height: 1.75rem;
    font-family: NotoSansRegular;
}

.ui.header.NotFoundPage__message{
    font-weight: 500;
}

@media(max-width:767px){
    .ui.header.NotFoundPage__header{
        margin-top: 3rem;
    }
}
@media(min-width: 1024px){
    h1.ui.header{
        font-size: 5.5rem;
    }
}
