/*******************************
     User Global Variables
*******************************/
@primaryColor: #FDBA05;
@secondaryColor: #FF2200;
@fontName: 'NotoSansCJK';
@fontSize: 13px;

@yellow: #FDBA05;
@red: #FF2200;
@green: #14AE79;
@orage: #a63871;
@pink: #ECBFBB;
@lightPink: #ECBFBB;
@blue: #153391;
@purple: #7F58AF;
@lightBlue #64c5eb;
@teal: #44D7B6;
@warningColor: #e95b55;