.ui.image.PointsHistoryLink__icon {
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
}

.ui.image.ResetPasswordLink__icon {
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
}

.ui.image.PointsSummary__datepicker-icon{
  position: absolute;
  right: 10px;
  cursor: pointer;
}

.ui.image.FinanceLink__icon {
  position: absolute; 
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
}

.ui.image.PointsTransactions__point-mark,
.ui.image.ExercisesTransactions__point-mark{
  margin-top: 1.5rem;
}
