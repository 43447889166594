.ui.menu.NavigationMenu　{
    height: 59px;
    position: relative;
    margin-bottom: 0;
    width: 100%; 
    z-index: 1000;
    margin: 0;
    background-color: white !important;
}

@media(max-width: 540px) {
    .ui.menu.NavigationMenu:before {
        content: "";
        display: inline-block;
        position: absolute;
        bottom: -50px;
        height: 50px;
        width: 100%;
        background-image: url("/images/header_bar.png");
        background-size: cover;
        background-position: center bottom;
    }
}

.ui.menu > .item.NavigationMenu__logoContainer {
    position: absolute;
    padding-top: 0;
    padding-bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}

.ui.menu > .item.NavigationMenu__logoContainer img {
    max-width: 168px;
    max-height: 28px;
    object-fit: contain;
}

.ui.menu > .item.Sidebar__item {
    padding: 1.6rem 1.32rem;
}

.ui.menu > .item.Sidebar__item:last-child::before {
    display: none;
}

.ui.menu > .item.Sidebar__item > .Sidebar__item__label{
    font-size: 1rem;
    line-height: 24px;
    letter-spacing: -0.16px;
    text-align: center;
    color: #1C1C1C;
    margin-left: 0.75rem;
    font-family: Hiragino Sans;
}

.ui.menu > .item.Sidebar__item > .Sidebar__item__sublabel{
    font-size: 1rem;
    line-height: 24px;
    letter-spacing: -0.16px;
    text-align: center;
    color: #1C1C1C;
    margin-left: 2.3rem;
    font-family: NotoSansRegular;
}

.ui.menu > .item.Sidebar__item > .Sidebar__point__sublabel_{
    font-size: 1rem;
    line-height: 24px;
    letter-spacing: -0.16px;
    text-align: center;
    color: #1C1C1C;
    margin-left: 1.8rem;
    font-family: Hiragino Sans;
}
