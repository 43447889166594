.ui.button.LoginButton {
    width: 100%;
    max-width: 420px;
    height: 75px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ui.button.LoginButton > span {
    margin-top: 25px;
    padding-left: 15px;
    transform: translateY(-50%);
    font-size: 1.30rem;
    line-height: 1.93rem;
    letter-spacing: 0.28px;
    color: rgba(#000, 0.54);
}

.ui.button:hover {
  box-shadow: none !important;
}

@media(max-width: 300px){
    .ui.button.LoginButton {
        width: 80%;
    }
    .ui.button.LoginButton > span {
        font-size: 1rem;
    }
}

@media(max-width: 240px){
    .ui.button.LoginButton > .LoginButton__icon {
        display: none;
    }
}
