/*******************************
         Site Overrides
*******************************/

.ui.grid.FullHeightGrid{
  height: 100vh;
}

.ui.grid.FullWidthGrid{
  width: 100%;
}

.ui.grid.NoMargins{
  margin-left: 0;
  margin-right: 0;
}

.ui.grid.HomePage__Grid,
 .ui.grid.CassetteInfo__Grid {
  height: 100%;
  width: 100%;
}

.ui.grid.ProfilePage__Grid {
  width: 100%;
  height: 100%;
  margin-left: 0;
  margin-right: 0;
  margin-top: 40px;
}

.ui.grid.PointsTransactions__transactions,
 .ui.grid.ExercisesTransactions__transactions{
  height: 100%;
  margin-bottom: -28px;
}

.ui.grid.PointsTransactions__transactions .PointsTransactions__transactions_item:last-child,
.ui.grid.ExercisesTransactions__transactions .ExercisesTransactions__transactions_item:last-child{
  height: 100%;
}

.ui.grid.ProfilePage__Grid > .row,
.ui.grid.PointsTransactions__transactions > .row,
.ui.grid.ExercisesTransactions__transactions > .row{
  padding-bottom: 0;
  margin-bottom: 0;
  padding-top: 0;
  border: none;
}

.ui.grid.ProfilePage__Grid > .row > .column, 
.ui.grid.PointsTransactions__transactions > .row > .column,
.ui.grid.ExercisesTransactions__transactions > .row > .column {
   padding-left: 0;
   padding-right: 0; 
}

@media(min-width: 1024px) {
  .ui.grid.ProfilePage__Grid,
  .ui.grid.PointsTransactions__transactions,
  .ui.grid.ExercisesTransactions__transactions{
    margin-top: 5rem;
  }
}

